<template>
    <v-dialog
        :key="shipmentId"
        :max-width="dialogWidth"
        overlay=false
        persistent
        scrollable
        transition="dialog-bottom-transition"
        v-model="open"
    >
        <div class="no-gutters d-flex flex-row" :style="'width: ' + dialogWidth">
            <v-card width="600px" v-show="infoCard" ref="shippingInfoCard">
                <v-card-title class="success white--text">{{ $t('message.shippingInfo') + ": " + mainDocument }}</v-card-title>
                <v-card-text class="pa-3 overflow-y-auto" :style="'height: 450px !important;'">
                    <v-overlay
                        :value="loading.info"
                        absolute
                        opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.buyer') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Shipment.Customer.title }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.supplier') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Shipment.Supplier.title }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.paymentTerms') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Paymentstatement.title }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.portOfLoading') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Originport.title }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.countryOfLoading') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Origin.name }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.destinationPort') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Destinationport.title }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.destinationCountry') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Destination.name }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.osd') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ formatDate(shippingInfo.Shipment.shipweekdate) }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.etd') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ formatDate(shippingInfo.Shipment.etddate) }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.eta') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ formatDate(shippingInfo.Shipment.etadate) }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.sailingConfirmed') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0">
                            <span class="text--black" v-if="shippingInfo.Shipment.sailing == 1">{{ $t('message.yes') }}</span>
                            <span class="text--black" v-else>{{ $t('message.no') }}</span>
                        </v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.shipmentStatus') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0">
                            <span class="text--black" v-if="shippingInfo.Shipment.shipmentstatus_id == 6">{{ shippingInfo.Shipment.shipmentstatus_text }}</span>
                        </v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.blNo') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Shipment.blno }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.containerNos') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0">
                            <div class="text--black" v-for="(container,index) in containers" :key="index">{{ container.Container.title }}</div>
                        </v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.carrier') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Agent.title }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.documentsSentOn') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ formatDate(shippingInfo.Shipment.courierdate) }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.documentsCourier') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Courier.title }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.documentsCourierAwb') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Shipment.courier }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.containerDelivered') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black" v-if="shippingInfo.Shipment.hascontainerdate == 1 && containerDate > etaDate ">{{ formatDate(shippingInfo.Shipment.containerdate) }}</span></v-col>
                    </v-row>
                    <v-row class="py-0" v-if="false">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.emptyContainerReturned') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black"></span></v-col>
                    </v-row>
<!--                    <v-data-table-->
<!--                        :headers="shippingUpdatesHeaders"-->
<!--                        :items="shippingUpdates"-->
<!--                        :loading="loading.updates"-->
<!--                        calculate-widths-->
<!--                        class="mt-2"-->
<!--                        dense-->
<!--                        disable-sort-->
<!--                        disable-pagination-->
<!--                        hide-default-footer-->
<!--                    >-->
<!--                        <template v-slot:top>-->
<!--                            <div class="font-weight-bold">{{ $t('message.appicShipmentUpdatesOnly') }}</div>-->
<!--                        </template>-->
<!--                        <template v-slot:item.ShipmentUpdate.date_created="{ item }">-->
<!--                            <span class="text-no-wrap">{{ formatDate(item.ShipmentUpdate.date_created) + " " + item.ShipmentUpdate.time_created }}</span>-->
<!--                        </template>-->
<!--                        <template v-slot:item.ShipmentUpdate.update="{ item }">-->
<!--                            <div v-for="update in item.ShipmentUpdate.update.split(',')">{{ update }}</div>-->
<!--                        </template>-->
<!--                        <template v-slot:item.ShipmentUpdate.value="{ item }">-->
<!--                            <span v-if="item.ShipmentUpdate.value != null">{{ item.ShipmentUpdate.value }}</span>-->
<!--                            <span v-else>{{ $t('message.na') }}</span>-->
<!--                        </template>-->
<!--                    </v-data-table>-->
                    <v-data-table
                        :headers="shippingNotificationsHeaders"
                        :items="shippingNotifications"
                        :loading="loading.notifications"
                        calculate-widths
                        class="mt-2"
                        dense
                        disable-sort
                        disable-pagination
                        hide-default-footer
                    >
                        <template v-slot:top>
                            <div class="font-weight-bold">{{ $t('message.notifications') }}</div>
                        </template>
                        <template v-slot:item.Notification.senddate="{ item }">
                            <span class="text-no-wrap">{{ formatDate(item.Notification.senddate.split(' ')[0]) + " " + item.Notification.senddate.split(' ')[1] }}</span>
                        </template>
                        <template v-slot:item.Notification.message="{ item }">
                            <div v-for="message in item.Notification.message.split(',')">{{ message }}</div>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="default"
                        small
                        @click="infoCard = false"
                    >
                        {{ $t('message.close') }}
                    </v-btn>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="closeAll"
                    >
                        {{ $t('message.closeAll') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-card width="600px" v-show="documentsCard" :class="dialogWidthInt > 600 ? 'ml-3' : ''" ref="shippingDocumentsCard">
                <v-card-title class="success white--text">{{ $t('message.supportingDocuments') }}</v-card-title>
                <v-card-text class="pa-3 overflow-y-auto" :style="'height: 450px !important;'">
                    <v-simple-table class="appic-table-light specification-tables" dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="width-1-pct"></th>
                                <th>{{ $t('message.source') }}</th>
                                <th>{{ $t('message.name') }}</th>
                                <th>{{ $t('message.uploaded') }}</th>
                                <th>{{ $t('message.actions') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="document in shippingDocuments">
                                <td>
                                    <v-checkbox
                                        :value="document.Document.location"
                                        class="mt-0"
                                        dense
                                        hide-details
                                        v-model="selectedDocuments"
                                    ></v-checkbox>
                                </td>
                                <td>{{ document.Document.source }}</td>
                                <td>{{ document.Document.name }}</td>
                                <td>{{ formatDate(document.Document.uploaded) }}</td>
                                <td>
                                    <v-btn icon @click="viewFile(document.Document.location)">
                                        <v-icon class="pl-2" style="color: darkgray !important;" small>fas fa-cloud-download-alt</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-btn
                        :loading="loading.merge"
                        :disabled="selectedDocuments.length === 0"
                        class="mt-2"
                        color="default"
                        small
                        @click="downloadMergedPdf()"
                        v-if="shippingDocuments.length > 0 && $can('merge','ShippingDocuments') && false"
                    >
                        <v-icon class="pr-2" style="color: darkgray !important;" small>fas fa-cloud-download-alt</v-icon>
                        {{ $t('message.downloadShippingDocumentsMergedPdf') }}
                    </v-btn>
                    <span v-if="loading.invoice" class="green--text darken-1">{{ $t('message.pleaseWait') }}</span>
                    <v-btn
                        :loading="loading.mergeV2"
                        :disabled="selectedDocuments.length == 0"
                        class="mt-2"
                        small
                        @click="downloadMergedPdfV2()"
                        v-if="shippingDocuments.length > 0 && $can('merge','ShippingDocuments') && loading.invoice === false"
                    >
                        <v-icon class="pr-2" style="color: darkgray !important;" small>fas fa-cloud-download-alt</v-icon>
                        {{ $t('message.downloadShippingDocumentsMergedPdf') }}
                    </v-btn>
                    <div class="text-center" v-if="shippingDocuments.length === 0">{{ $t('message.noShippingDocumentsAvailable') }}</div>
                    <div class="font-weight-bold pt-4" v-if="appOnlyDocuments.length > 0">{{ $t('message.appOnlyDocuments') }}</div>
                    <v-simple-table class="appic-table-light specification-tables pt-2" dense v-if="appOnlyDocuments.length > 0">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th>{{ $t('message.source') }}</th>
                                <th>{{ $t('message.name') }}</th>
                                <th>{{ $t('message.uploaded') }}</th>
                                <th>{{ $t('message.actions') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="document in appOnlyDocuments">
                                <td>{{ document.Document.source }}</td>
                                <td>{{ document.Document.name }}</td>
                                <td>{{ formatDate(document.Document.uploaded) }}</td>
                                <td>
                                    <v-btn icon @click="viewFile(document.Document.location)">
                                        <v-icon class="pl-2" style="color: darkgray !important;" small>fas fa-eye</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="documentsCard = false"
                    >
                        {{ $t('message.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>
        <PrintInvoiceBlob
            :key="invoiceId"
            :invoice-id="invoiceId"
            :is-tax-invoice="isTaxInvoice"
            @pdf-uploading="invoiceUploading"
            @pdf-uploaded="invoiceUploaded"
            @pdf-not-uploaded="invoiceNotUploaded()"
            v-if="hasInvoice && $can('merge','ShippingDocuments')"
        ></PrintInvoiceBlob>
    </v-dialog>
</template>

<script>
import {formatDate, log, numberFormat} from "Helpers/helpers";
import {api} from "Api";
import Api2Pdf from "api2pdf";
import {mapFields} from "vuex-map-fields";
import {mapGetters} from "vuex";

const PrintInvoiceBlob = () => import('Views/v1/invoices/PrintInvoiceBlob');

export default {
    name: "ShippingInfoAndDocumentsV2",
    components: {PrintInvoiceBlob},
    props: {
        mainDocument: {
            type: String
        },
        openDialog: {
            type: Boolean
        },
        shipmentId: {
            type: Number
        },
        shippingDocuments: {
            type: Array,
            default: () => {
                return []
            }
        },
        appOnlyDocuments: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            cardHeight: '100',
            containers: [],
            documentsCard: true,
            hasInvoice: false,
            infoCard: true,
            invoiceId: false,
            isTaxInvoice: false,
            loading: {
                compress: false,
                info: false,
                invoice: false,
                merge: false,
                mergeV2: false,
                notifications: false,
                updates: false
            },
            open: false,
            pdfComponentKey: 0,
            selectedDocuments: [],
            shippingInfo: {
                Agent: {},
                Courier: {},
                Destination: {},
                Destinationport: {},
                Origin: {},
                Originport: {},
                Paymentstatement: {},
                Shipment: {
                    Customer: {},
                    Supplier: {},
                },

            },
            shippingNotifications: [],
            shippingNotificationsHeaders: [
                {
                    id: 0,
                    text: this.$t('message.dateSent'),
                    value: 'Notification.senddate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 1,
                    text: this.$t('message.recipients'),
                    value: 'Notification.recipients',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.message'),
                    value: 'Notification.message',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 3,
                    text: this.$t('message.sender'),
                    value: 'Notification.sender',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
            ],
            shippingUpdates: [],
            shippingUpdatesHeaders: [
                {
                    id: 0,
                    text: this.$t('message.date'),
                    value: 'ShipmentUpdate.date_created',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 1,
                    text: this.$t('message.update'),
                    value: 'ShipmentUpdate.update',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.value'),
                    value: 'ShipmentUpdate.value',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 3,
                    text: this.$t('message.user'),
                    value: 'ShipmentUpdate.user',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                }
            ]
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters('user',{
            api2Pdf : 'api2Pdf'
        }),
        containerDate(){
            return new Date(this.shippingInfo.Shipment.containerdate)
        },
        dialogWidth() {
            let width = 0
            if(this.infoCard) width = width + 600
            if(this.documentsCard) width = width + 600
            if(width > 0) {
                return width.toString() + 'px !important'
            } else {
                return '0px !important'
            }
        },
        dialogWidthInt(){
            let width = 0
            if(this.infoCard) width = width + 600
            if(this.documentsCard) width = width + 600
            return width
        },
        etaDate(){
            return new Date(this.shippingInfo.Shipment.etadate)
        }
    },
    methods: {
        closeAll() {
            this.documentsCard = false
            this.infoCard = false
        },
        downloadMergedPdf () {
            if(this.api2Pdf.key != '') {
                if (this.selectedDocuments.length > 1) {
                    this.loading.merge = true
                    let self = this
                    let a2pClient = new Api2Pdf(this.api2Pdf.key)
                    a2pClient.merge(this.selectedDocuments, true, this.mainDocument + " MERGED DOCUMENTS" + '.pdf').then(function (result) {
                        if (result.success == true) {
                            let tab = window.open(result.pdf, '_blank')
                            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                            this.openedTabs.push(tab.name)
                        }
                    });
                    self.loading.merge = false
                    this.open = false
                    this.$toast.success(this.$t('message.successes.pdfMergedPleaseWaitForDownload'),
                        {
                            timeout: 15000,
                            color: 'info',
                            classes: ['icon-float-left'],
                            icon: 'check_circle_outline',
                            x: 'centered',
                            y: 'top'
                        }
                    )
                } else if (this.selectedDocuments.length == 1) {
                    alert(this.$t('message.selectMoreThanOneFile'))
                } else {
                    alert(this.$t('message.noFilesSelected'))
                }
            } else {
                alert(this.$t('message.api2PdfKeyNotAvailable'))
            }
        },
        async downloadMergedPdfV2 (){
            if (this.selectedDocuments.length > 1) {
                this.loading.mergeV2 = true
                try {
                    let self = this
                    setTimeout(function(){
                        self.loading.mergeV2 = false
                    }, 1000)

                    this.$toast(this.$t('message.successes.mergedFileReadyMoment'),{
                        classes: ['text--white'],
                        color: 'info',
                        timeout: 15000,
                        x: 'center',
                        y: 'top'
                    })

                    let mergedPdf = await api.post('/documents/merge-pdf',{
                        files: this.selectedDocuments
                    }, {
                        responseType: 'blob'
                    })

                    const url = window.URL.createObjectURL(new Blob([mergedPdf.data], { type: 'application/pdf' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = '_blank'
                    link.setAttribute('download', this.mainDocument + " MERGED DOCUMENTS" + '.pdf');
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);

                } catch (error) {
                    this.loading.merge = false
                    this.$toast.error(this.$t('message.errors.couldNotMergeFiles') + ": " + error,{
                        timeout: 7000,
                        x: 'center',
                        y: 'top'
                    })
                }

            } else if (this.selectedDocuments.length == 1) {
                alert(this.$t('message.selectMoreThanOneFile'))
            } else {
                alert(this.$t('message.noFilesSelected'))
            }
        },
        formatDate,
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        loadContainers(){
            return new Promise((resolve, reject) => {
                api
                    .get("/contracts/" + this.shipmentId + '/containers')
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        invoiceNotUploaded() {
            this.loading.merge = false
        },
        invoiceUploaded(url) {
            this.loading.invoice = false
            this.shippingDocuments.forEach((document, idx) => {
                if(document.Document.name == 'Invoice' || document.Document.name == 'Tax Invoice'){
                    this.shippingDocuments[idx].Document.location = url
                }
            })
            this.selectedDocuments = []
            this.shippingDocuments.forEach(document => {
                this.selectedDocuments.push(document.Document.location)
            })
            this.loading.merge = false
        },
        invoiceUploading() {
            this.loading.invoice = true
        },
        loadShippingInfo(){
            return new Promise((resolve, reject) => {
                api
                    .get("/shipments/" + this.shipmentId)
                    .then(response => {
                        resolve(response.data.data[0])
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        loadShippingNotifications(){
            return new Promise((resolve, reject) => {
                api
                    .get("/notifications/shipments/" + this.shipmentId)
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        loadShippingUpdates(){
            return new Promise((resolve, reject) => {
                api
                    .get("/shipments/" + this.shipmentId + "/updates")
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        viewFile (file) {
            let rand = new Date().getTime()
            let tab = window.open(file + '?v=' + rand, '_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    watch: {
        documentsCard(val) {
            if(val == false && this.infoCard == false) this.open = false
        },
        infoCard(val) {
            if(val == false && this.documentsCard == false) this.open = false
        },
        open (value) {
            if(value == false){
                this.$emit('dialog-closed')
            }
        },
        openDialog (value) {
            if(value){
                this.open = value
                this.loading.info = true
                this.loading.notifications = true
                this.loading.updates = true
                this.selectedDocuments = []
                this.loadShippingInfo()
                    .then(response => {
                        this.shippingInfo = response
                        this.loadContainers()
                            .then(response => {
                                this.containers = response
                                this.loadShippingNotifications()
                                    .then((response)=>{
                                        this.shippingDocuments.forEach(document => {
                                            if([91].includes(document.Document.doctype_id) == false) {
                                                this.selectedDocuments.push(document.Document.location)
                                            }
                                            if((document.Document.name == 'Tax Invoice' || document.Document.name == 'Invoice' || document.Document.name == 'Commercial Invoice') && document.Document?.invoice_id && document.Document?.existing_pdf == false){
                                                log('here!')
                                                this.loading.invoice = true
                                                this.hasInvoice = true
                                                this.invoiceId = document.Document.invoice_id
                                                if(document.Document.name == 'Tax Invoice') this.isTaxInvoice = true
                                            }
                                        })
                                        this.shippingNotifications = response
                                        this.loading.info = false
                                        this.loading.notifications = false
                                        this.loading.updates = false
                                        this.infoCard = true
                                        this.documentsCard = true
                                    })
                            })
                    })
            }
        }
    }
}
</script>

<style>
.text--black {
    color: black !important;
}

.v-dialog {
    min-width: 500px !important;
    max-width: 700px !important;
}
</style>